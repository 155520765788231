
import { defineComponent } from "vue";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import { cloneDeep } from "lodash";

export default defineComponent({
  name: "AddressItems",
  components: {
    InputText,
    Button,
  },
  props: {
    address: {
      type: Array,
    },
    addressName: {
      type: String,
      default: "address",
    },
    inline: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      address_items: [{ [this.addressName]: "" }] as any[],
    };
  },
  mounted() {
    this.updateAddressProp();
  },
  computed: {
    addressHeaderClass(): string {
      return "grid col-12 p-0 m-0 " + (this.inline ? "mt-2" : "mt-0");
    },
  },
  methods: {
    updateAddressProp() {
      if (this.address && this.address?.length > 0) {
        this.address_items = cloneDeep(this.address);
      }
    },
    addAddressLine(index: number) {
      this.address_items.splice(index + 1, 0, { [this.addressName]: "" });
      this.$emit("updateAddress", this.address_items);
    },
    updateAddress() {
      this.$emit("updateAddress", this.address_items);
    },
    deleteAddressLine(index: number) {
      this.address_items.splice(index, 1);
      this.$emit("updateAddress", this.address_items);
      if (this.address_items.length === 0) {
        this.address_items.push({ [this.addressName]: "" });
      }
    },
    moveToNextLine(index: number) {
      if (index + 1 === this.address_items.length) {
        this.addAddressLine(index);
      }

      this.$nextTick(() => {
        (this.$refs[`addressInput-${index + 1}`] as any)[0].$el.focus();
      });
    },
  },
});
