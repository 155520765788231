
import soquote, {
  LiItems,
  AddressItem,
  RepItem,
  ChangeDateItems,
  QtyItems,
} from "@/types/soquote";
import { replace } from "lodash";
import { SalesInquiryMap } from "@/types/state/salesInquiry";
import { defineComponent } from "vue";
import { mapActions, mapGetters, mapState } from "vuex";
import Search from "@/components/Search.vue";
import InputText from "primevue/inputtext";
import Dialog from "primevue/dialog";
import Dropdown from "primevue/dropdown";
import DataTable from "primevue/datatable";
import Button from "primevue/button";
import Column from "primevue/column";
import ColumnGroup from "primevue/columngroup";
import Row from "primevue/row";
import InputNumber from "primevue/inputnumber";
import Calendar from "primevue/calendar";
import CustomerService from "@/services/CustomerService";
import Customer, { basecontact } from "@/types/customer";
import Card from "primevue/card";
import Contact from "@/types/contact";
import ContactService from "@/services/ContactService";
import useVuelidate from "@vuelidate/core";
import { required, requiredIf, helpers } from "@vuelidate/validators";
import PartsService from "@/services/PartsService";
import SoQuoteService from "@/services/SOQuotesService";
import ProspectService from "@/services/ProspectService";
import SaleOppsService from "@/services/SaleOppsService";
import SalesService from "@/services/SalesService";
import Prospect from "@/types/prospect";
import Part from "@/types/part";
import { ResponseObject } from "@/types/response/response";
import SaleOpp from "@/types/saleOpps";
import SalesOrder, { LisItems } from "@/types/salesorder";
import ContactAutocomplete from "@/components/Autocompletes/Contact.vue";
import CollapsibleSection from "@/components/UI/CollapsibleSection.vue";
import Utils from "@/utility/utils";
import UserIdItem from "@/types/userIdItem";
import SplitButton from "primevue/splitbutton";
import EmailFileDialog from "@/components/UI/EmailFileDialog.vue";
import Textarea from "primevue/textarea";
import RepDataTable from "@/components/Reps/RepDataTable.vue";
import AddressItems from "@/components/UI/AddressItems.vue";
import SearchFile from "@/components/SearchFile.vue";
import LogsDataTable from "@/components/UI/LogsDataTable.vue";
import AttachmentRecords from "@/components/Attachments/AttachmentRecords.vue";
import SearchPartDialog from "@/components/Sales/Orders/SearchPartDialog.vue";

const customerService = new CustomerService();

export default defineComponent({
  name: "SO Quote Tab",

  components: {
    Search,
    InputText,
    Dialog,
    Dropdown,
    DataTable,
    Button,
    Column,
    ColumnGroup,
    Row,
    InputNumber,
    Calendar,
    ContactAutocomplete,
    CollapsibleSection,
    Card,
    SplitButton,
    EmailFileDialog,
    Textarea,
    RepDataTable,
    SearchFile,
    AddressItems,
    LogsDataTable,
    AttachmentRecords,
    SearchPartDialog,
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  validations() {
    return {
      existingOpportunity: {
        required,
      },
      existingSOQuote: {
        required,
      },
      soQuote: {
        co_code: { required },
      },
      newSoQuoteCust: {
        required: helpers.withMessage(
          "Please Select a Customer",
          requiredIf(() => {
            return this.soQuote?.prospect == "";
          }),
        ),
      },
      co_code: {
        required: helpers.withMessage(
          "Please Select a Company",
          requiredIf(() => {
            return this.soQuote?.co_code == "";
          }),
        ),
      },
    };
  },
  created() {
    this.currentUserId = this.getCurrentUserData.user_id;
    this.selectedQuoter = this.currentUserId.toUpperCase();
    this.initSoQuote();
    this.initControls();

    if (this.getTerms == null) {
      this.fetchTerms();
    }

    if (this.isNewSalesQuote) {
      let curDate = new Date();
      let valid_thru = new Date(curDate.getTime() + 30 * 24 * 60 * 60 * 1000);
      this.soQuote.date = this.formatDate(curDate, "QUOTED_DATE");
      this.soQuote.valid_thru = this.formatDate(valid_thru, "VALID_THRU_DATE");
      this.soQuote.co_code = this.getCompanyCodes[0].code;
      if (this.soQuote.li_items == undefined) {
        this.soQuote.li_items = [];
      }
    } else if (this.$attrs.tab != null) {
      this.soQuote = this.getActiveSaleTab(
        (this.$attrs.tab as SalesInquiryMap).id,
      ) as SalesInquiryMap;
      this.soQuote.date = this.soQuote.date
        ? this.formatDate(new Date(this.soQuote.date), "QUOTED_DATE")
        : "";

      const contactId = this.soQuote.contact;
      this.soQuote.contact_id = contactId;
      (this.soQuote as SalesInquiryMap).oldRecord.contact_id = contactId;
      if (this.soQuote.li_items == undefined) {
        this.soQuote.li_items = [];
      }
    }

    if (this.isNewSalesQuote) {
      if (this.soQuote.customer != "") {
        if (this.soQuote.customer_name) {
          this.newSoQuoteCust = this.soQuote.customer_name;
        } else {
          customerService
            .getCustomer(this.soQuote.customer, this.getClient)
            .then((response) => {
              const cust = response as Customer;
              this.newSoQuoteCust = cust.name as string;
              if ((response as any).cust_items.length > 0) {
                // First find by id if there are no coincidences then set the first returned value
                let cust = (response as any).cust_items.find(
                  (customer: any) =>
                    this.soQuote.customer &&
                    customer.cust_id == this.soQuote.customer,
                );

                if (!cust) {
                  cust = (response as any).cust_items[0];
                }
                this.newSoQuoteCust = cust.name;
              }
            });
        }
      }
      this.initProspect();
      this.selectedQuoter = this.soQuote.quoted_by;
      this.showClear = this.selectedQuoter ? true : false;
    }
  },
  data() {
    return {
      showEditPartDescriptionDialog: false,
      newRep: {
        rep: "",
        order_pct: "",
      },
      showEmailDialog: false,
      showChangeCustomerDialog: false,
      showPartSearchDialog: false,
      partSearch: "",
      parts: [] as Array<any>,
      soQuote: {} as SalesInquiryMap,
      salesOrder: {} as SalesOrder,
      part: {
        li: "",
        quantity: 1,
        number: "",
        date: "",
        disc_pct: "",
        wrap_desc: "",
      },
      cost_method: [
        {
          description: "Markup",
          value: "M",
        },
        {
          description: "Price File",
          value: "P",
        },
      ],
      newSoQuoteCust: "",
      newSoQuoteContact: "",
      prospectName: "",
      totalPrice: "",
      showPartModal: false,
      showAddExistingQuoteDialog: false,
      showAddOpportunityDialog: false,
      isLoadingOrder: false,
      isLoadingOpportunity: false,
      existingSOQuote: "",
      existingOpportunity: "",
      saleOpp: {} as SaleOpp,
      isLoadingSOQuote: false,
      /* tracking flag to detect whether an opp is loaded in the dialog
       * reset every time the dialog is closed and or when an existing quote is
       * loaded in the dialog - check for usages.
       */
      isOppLoadedInDialog: false,
      soQuoteService: new SoQuoteService(process.env.VUE_APP_ABSTRACTION_API),
      saleOppService: new SaleOppsService(process.env.VUE_APP_ABSTRACTION_API),
      partService: new PartsService(),
      prospectService: new ProspectService(process.env.VUE_APP_ABSTRACTION_API),
      salesService: new SalesService(),
      partLoading: false,
      submitLoading: false,
      selectedQuoter: "",
      currentUserId: "",
      isFormSubmitted: false,
      showClear: false,
      isLoadQuoteFormSubmitted: false,
      isLoadOppFormSubmitted: false,
      isLoadingQuote: false,
      isOpportunityEmpty: false,
      isOrderEmpty: false,
      statusMessage: "",
      disc_pct: "",
      status: [
        {
          description: "Closed",
          value: "C",
        },
        {
          description: "New",
          value: "N",
        },
        {
          description: "Backorder",
          value: "B",
        },
      ],
      editPartIndex: -1,
      editPartNumberIndex: -1,
      sectionsStatus: {
        customerDetails: true,
        orderDetails: true,
        pricingShippingDetails: true,
        logEntries: false,
        attachments: false,
      },
      topSplitButtonItems: [
        {
          label: "Create Order From Quote",
          icon: "pi pi-plus",
          command: this.createOrder,
        },
        {
          label: "Download Quote",
          icon: "pi pi-download",
          command: this.downloadQuote,
        },
        {
          label: "Email Quote",
          icon: "pi pi-at",
          command: this.handleEmailQuote,
        },
      ],
    };
  },
  computed: {
    ...mapState(["customerInquiry"]),
    ...mapGetters({
      getClient: "session/getClient",
      getTerms: "customerInquiry/getTerms",
      getPricingCodes: "customerInquiry/getPricingCodes",
      getCompanyCodes: "customerInquiry/getCompanyCodes",
      getUsers: "users/getUsers",
      getCurrentUserData: "session/getUser",
      getActiveSaleTab: "salesInquiry/getActiveOrderTab",
      getQuotes: "soQuotes/getQuote",
      getLoadingQuotes: "soQuotes/getLoadingQuotes",
    }),
    isNewSalesQuote(): boolean {
      return (this.$attrs as any).quoteId.includes("New");
    },
    associatedTypes(): Array<any> {
      return [
        { assoc_file: "Soquote", assoc_id: this.soQuote.id },
        { assoc_file: "Customer", assoc_id: this.soQuote.customer },
      ];
    },
    calculatedTotal(): string {
      let total = 0;

      const multipleOptions = this.soQuote.li_items.filter(
        (liItem: LiItems) => liItem.qty_items.length > 1,
      );

      if (multipleOptions.length > 0) {
        return "Can't calculate total with multiple quantity options";
      }

      this.flatQuoteLineItems.forEach((part: any) => {
        total += parseFloat(part.extended_price);
      });
      return total.toFixed(2);
    },
    flatQuoteLineItems() {
      const reformattedPartInfo: Array<any> = [];

      if (!this.soQuote.li_items) {
        return reformattedPartInfo;
      }

      this.soQuote.li_items?.forEach((LiItemObj: LiItems) => {
        if (LiItemObj.qty_items != null && LiItemObj.qty_items.length > 0) {
          const disc = +LiItemObj.disc_pct || 0.0;
          let index = 0;
          LiItemObj.qty_items?.forEach((qty_item_obj: QtyItems) => {
            let newQtyObject = {};
            const price = +qty_item_obj.price || 0.0;
            const qty = +qty_item_obj.qty || 0;
            const discAmount = price * (disc / 100) * qty;
            const priceTotal = price * qty - discAmount;

            newQtyObject = {
              li: LiItemObj.li,
              index: index,
              part: LiItemObj.part,
              qty: qty.toString(),
              wrap_desc: LiItemObj.wrap_desc,
              disc_pct: disc.toFixed(2).toString(),
              price: price.toFixed(2).toString(),
              material_cost: qty_item_obj.material_cost,
              labor_cost: qty_item_obj.labor_cost,
              fixed_overhead: qty_item_obj.fixed_overhead,
              var_overhead: qty_item_obj.var_overhead,
              other_cost: qty_item_obj.other_cost,
              total_cost: qty_item_obj.total_cost,
              markup_pct: qty_item_obj.markup_pct,
              extended_price: priceTotal.toFixed(2).toString(),
            };
            index++;
            reformattedPartInfo.push(newQtyObject);
          });
        } else {
          let newLiItemObj = {
            index: 0,
            li: LiItemObj.li,
            part: LiItemObj.part,
            disc_pct: LiItemObj.disc_pct,
            wrap_desc: LiItemObj.wrap_desc,
            qty_items: LiItemObj.qty_items,
            item_cost: LiItemObj.item_cost,
            desc_items: LiItemObj.desc_items,
            li_rep_items: LiItemObj.li_rep_items,
            cost: LiItemObj.cost,
          };
          reformattedPartInfo.push(newLiItemObj);
        }
      });
      return reformattedPartInfo;
    },
  },
  mounted() {
    if (!this.isNewSalesQuote) {
      this.topSplitButtonItems = [
        {
          label: "Copy Opportunity",
          icon: "pi pi-plus",
          command: this.openAddOpportunityDialog,
        },
        {
          label: "Copy Exising Quote",
          icon: "pi pi-plus",
          command: this.openAddExistingQuoteDialog,
        },
      ];
    }
  },
  methods: {
    ...mapActions({
      fetchTerms: "customerInquiry/getTerms",
      fetchControls: "customerInquiry/getControls",
      removeQuotePDF: "soQuotes/removeQuotePDF",
      addNotification: "notification/add",
      updateOpenedSalesInquiry: "salesInquiry/updateOpenedSalesInquiry",
      addOpenedSalesOrder: "salesInquiry/addOpenedSalesInquiry",
      getQuotePDF: "soQuotes/getQuotePDF",
      setPosCustomer: "pos/setCustomer",
    }),
    handleQuoterSelected(event: any) {
      this.soQuote.quoted_by = event.value;
    },
    removePart(slotProps: any) {
      const { data } = slotProps;

      const index = this.soQuote.li_items.findIndex(
        (liItem: LiItems) => liItem.part === data.part,
      );

      if (index === -1) {
        return;
      }

      const soQuote = this.soQuote.li_items[index];

      if (soQuote.qty_items.length === 1) {
        this.soQuote.li_items.splice(index, 1);
      } else {
        this.soQuote.li_items[index].qty_items.splice(data.index, 1);
      }
    },
    setEditpartIndex(index: number) {
      this.editPartIndex = index;
      this.showEditPartDescriptionDialog = true;
    },
    savePartDescription() {
      this.showEditPartDescriptionDialog = false;
      this.soQuote.li_items[this.editPartIndex].wrap_desc =
        this.flatQuoteLineItems[this.editPartIndex].wrap_desc;
    },
    resetEditpartIndex() {
      this.showEditPartDescriptionDialog = false;
      this.flatQuoteLineItems[this.editPartIndex].wrap_desc =
        this.soQuote.li_items[this.editPartIndex].wrap_desc;
    },
    onCloseEditPartDescription() {
      this.flatQuoteLineItems[this.editPartIndex].wrap_desc =
        this.soQuote.li_items[this.editPartIndex].wrap_desc;
      this.editPartIndex = -1;
    },
    setEditPartNumberIndex(index: number) {
      this.editPartNumberIndex = index;
    },
    savePartNumber(index: number) {
      if (!this.flatQuoteLineItems[index].part) {
        this.addNotification({
          message: "Select a valid part.",
          type: "error",
        });
        return;
      }
      const priceService = new PartsService();
      priceService
        .getPriceByPartId(
          this.flatQuoteLineItems[index].part,
          this.getClient,
          this.soQuote.li_items[
            this.editPartNumberIndex
          ].qty_items[0].qty.toString(),
          this.soQuote.code,
          this.soQuote.customer,
          this.soQuote.date,
        )
        .then((response) => {
          const res = response as unknown as ResponseObject;
          this.soQuote.li_items[index].qty_items[0].price = res.price || 0;
          this.soQuote.li_items[index].wrap_desc = res.wrap_desc || "";
        });
      this.soQuote.li_items[index].part = this.flatQuoteLineItems[index].part;
      this.editPartNumberIndex = -1;
    },
    resetEditPartNumberIndex(index: number) {
      this.flatQuoteLineItems[index].part = this.soQuote.li_items[index].part;
      this.editPartNumberIndex = -1;
    },
    formatCurrency(amount: string) {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      });

      if (isNaN(parseFloat(amount))) {
        return amount;
      }

      return formatter.format(parseFloat(amount));
    },
    updateQuantity(context: any, event: any) {
      const { data } = context;

      const index = this.soQuote.li_items.findIndex(
        (liItem: LiItems) => liItem.part === data.part,
      );

      if (index === -1) {
        return;
      }

      const qty = event.value;
      this.soQuote.li_items[index].qty_items[data.index].qty = qty;
    },
    updatePrice(context: any, event: any) {
      const { data } = context;
      const index = this.soQuote.li_items.findIndex(
        (liItem: LiItems) => liItem.part === data.part,
      );

      if (index === -1) {
        return;
      }

      const price = event.value;
      this.soQuote.li_items[index].qty_items[data.index].price = price;
    },
    updateDiscPercent(context: any, event: any) {
      const { data } = context;
      const index = this.soQuote.li_items.findIndex(
        (liItem: LiItems) => liItem.part === data.part,
      );

      if (index === -1) {
        return;
      }

      const disc_pct = event.value;
      this.soQuote.li_items[index].disc_pct = disc_pct;
    },
    async createOrder() {
      const isCompanyFieldPopulated = await this.v$.co_code.$validate();
      const isCustFieldPopulated = await this.v$.soQuote.$validate();

      if (isCompanyFieldPopulated && isCustFieldPopulated) {
        this.isLoadingOrder = true;

        this.mapQuoteToOrder();
        await this.addRepToOrder();
        this.isOppLoadedInDialog = false;

        await this.submit();
        this.salesService
          .postOrder(this.salesOrder)
          .then((response) => {
            const saleId = (response as any).recordId;
            this.openOrderById(saleId);
            this.addNotification({
              message: `Successfully Created Sale Id ${saleId}.`,
              type: "success",
            });
          })
          .catch((error) => {
            this.isLoadingOrder = false;
            this.addNotification({
              message: `${error.messagge}`,
              type: "error",
            });
          })
          .finally(() => {
            this.isLoadingOrder = false;
          });
      }
    },
    openOrderById(id: string) {
      this.salesService
        .getOrderById(
          id,
          "cust_name rep_name bill_to_name hold_code_desc order_amount cust_resale currency_code exchange_rate pending_ship",
        )
        .then((response: any) => {
          this.isLoadingOrder = false;
          const itemToAdd = response as SalesInquiryMap;
          itemToAdd["saleType"] = "orders";
          itemToAdd["oldRecord"] = {} as any;
          this.addOpenedSalesOrder(response);
          this.$router.push(`/sales/orders/${response.so_id}`);
        })
        .catch((error) => {
          this.isLoadingOrder = false;
        });
    },
    formatQuoteToOrderPart(object: SalesInquiryMap) {
      const reformattedPartInfo: Array<LisItems> = [];
      object.li_items?.forEach((qtyObject: LiItems) => {
        if (qtyObject.qty_items != null && qtyObject.qty_items.length > 0) {
          qtyObject.qty_items?.forEach((partObject: QtyItems) => {
            let transformedlistItemObject: LisItems;
            transformedlistItemObject = {
              lis: (reformattedPartInfo.length + 1).toString(),
              li_parts: qtyObject.part,
              li_order_qtys: Number(partObject.qty).toFixed().toString() || "",
              li_prices: partObject.price
                ? Number(partObject.price).toFixed(2).toString()
                : "",
              li_wrap_desc: partObject.wrap_desc,
              li_sched_dates_items: [
                {
                  li_sched_dates: Utils.formatDate(new Date()),
                  li_sched_qtys:
                    Number(partObject.qty).toFixed().toString() || "",
                },
              ],
            } as unknown as LisItems;
            reformattedPartInfo.push(transformedlistItemObject);
          });
        }
      });
      this.salesOrder.lis_items = reformattedPartInfo;
    },
    mapQuoteToOrder() {
      this.salesOrder.contact_email = this.soQuote.email;
      this.salesOrder.phone = this.soQuote.phone;
      this.salesOrder.co_code = this.soQuote.co_code;
      this.salesOrder.terms_code = this.soQuote.terms;
      this.salesOrder.cust_code = this.soQuote.code;
      (this.salesOrder.lis_items as unknown) = this.soQuote.li_items || [];
      this.salesOrder.date = Utils.formatDate(new Date());
      this.salesOrder.sold_to = this.soQuote.customer;
      this.salesOrder.contact = this.soQuote.contact_id;
      this.salesOrder.status = this.soQuote.status;
      // this.salesOrder.rep_items = this.soQuote.rep_items;
      this.formatQuoteToOrderPart(this.soQuote as SalesInquiryMap);
    },
    addRepToOrder() {
      return new Promise((resolve) => {
        customerService
          .getCustomer(this.soQuote.customer, this.getClient)
          .then((response) => {
            const cust = response as Customer;
            (this.salesOrder as any).rep_items = cust.rep_items;
            resolve(cust);
          });
      });
    },
    openAddExistingQuoteDialog() {
      this.showAddExistingQuoteDialog = true;
    },
    openAddOpportunityDialog() {
      this.showAddOpportunityDialog = true;
    },
    resetFields(fieldCode: string) {
      switch (fieldCode) {
        case "QUOTES":
          this.isLoadQuoteFormSubmitted = false;
          this.isLoadingQuote = false;
          this.existingSOQuote = "";
          break;
        case "OPPS":
          this.existingOpportunity = "";
          this.isLoadingOpportunity = false;
          this.isLoadOppFormSubmitted = false;
          break;
      }
      this.statusMessage = "";
    },
    async populateDialog() {
      this.isLoadQuoteFormSubmitted = true;

      const isValidQuoteInput = await this.v$.existingSOQuote.$validate();

      if (isValidQuoteInput) {
        this.isLoadingSOQuote = true;
        this.isLoadingQuote = true;
        this.isOrderEmpty = false;
        this.statusMessage = "";

        this.soQuoteService
          .getSOQuotes(this.existingSOQuote)
          .then((response) => {
            this.isOppLoadedInDialog = false;
            if ((response as any).soquote_items?.length === 1) {
              const SOQuoteRecord = (response as any)
                .soquote_items[0] as SalesInquiryMap;
              SOQuoteRecord.id = "";
              this.soQuote = SOQuoteRecord;
              this.setCust({ cust_id: this.soQuote.customer } as Customer);
              if (this.soQuote.customer != "") {
                customerService
                  .getAllCustomers({
                    selection: this.soQuote.customer,
                  })
                  .then((response) => {
                    if ((response as any).cust_items.length > 0) {
                      let cust = (response as any).cust_items.find(
                        (customer: any) =>
                          this.soQuote.customer &&
                          customer.cust_id == this.soQuote.customer,
                      );

                      if (!cust) {
                        cust = (response as any).cust_items[0];
                      }
                      this.newSoQuoteCust = cust.name;
                    }
                    this.isLoadingSOQuote = false;
                    this.showAddExistingQuoteDialog = false;
                    this.existingSOQuote = "";
                    this.statusMessage = "";
                    this.isLoadQuoteFormSubmitted = false;
                  });
                this.showAddExistingQuoteDialog = false;
                this.existingSOQuote = "";
              }
              this.initProspect();
            } else {
              this.isLoadingSOQuote = false;
              this.isLoadingQuote = false;
              this.isOrderEmpty = true;
              this.statusMessage = "No record found";
            }
          })
          .catch(() => {
            this.isLoadQuoteFormSubmitted = false;
            this.statusMessage = "Failed to load quote";
          });
      }
    },
    async populateDialogWithOpp() {
      const isValidOppInput = await this.v$.existingOpportunity.$validate();

      if (isValidOppInput) {
        this.isLoadOppFormSubmitted = false;
        this.isLoadingOpportunity = true;
        this.isOpportunityEmpty = false;
        this.statusMessage = "";

        this.saleOppService
          .getSaleOpps(
            this.existingOpportunity,
            1,
            1,
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "cust_name",
          )
          .then((response) => {
            if ((response as any).saleopp_items?.length === 1) {
              this.saleOpp = (response as any).saleopp_items[0];
              const opportunityRecord = (response as any)
                .saleopp_items[0] as SaleOpp;
              this.isLoadingOpportunity = false;
              this.isOppLoadedInDialog = true;

              // MAP QUOTE TO SALE OPP
              this.soQuote.id = "";
              this.soQuote.date = Utils.formatDate(new Date());
              this.soQuote.prospect = opportunityRecord.prospect || "";
              this.soQuote.customer = opportunityRecord.cust;
              this.soQuote.email = "";
              this.soQuote.phone = "";
              this.soQuote.quoted_by = opportunityRecord.assigned_to || "";
              this.selectedQuoter = opportunityRecord.assigned_to || "";

              if (this.soQuote.customer != "") {
                this.setCust(
                  { cust_id: this.soQuote.customer } as Customer,
                  opportunityRecord.contact,
                );
                customerService
                  .getAllCustomers({
                    selection: this.soQuote.customer,
                  })
                  .then((response) => {
                    if ((response as any).cust_items.length > 0) {
                      let cust = (response as any).cust_items.find(
                        (customer: any) =>
                          this.soQuote.customer &&
                          customer.cust_id == this.soQuote.customer,
                      );

                      if (!cust) {
                        cust = (response as any).cust_items[0];
                      }
                      this.newSoQuoteCust = cust.name;
                    }
                    customerService
                      .getCustomer(this.soQuote.customer, this.getClient)
                      .then((response) => {
                        this.soQuote.address_items = (response as Customer)
                          .address_items as Array<AddressItem>;
                        if (opportunityRecord.est_close) {
                          let oppDate = new Date(opportunityRecord.est_close);
                          this.soQuote.date = Utils.formatDate(oppDate);
                          let valid_thru = new Date(
                            oppDate.getTime() + 30 * 24 * 60 * 60 * 1000,
                          );
                          this.formatDate(valid_thru, "VALID_THRU_DATE");
                        }
                      });
                    if (this.soQuote.contact_id != "") {
                      this.soQuoteContact([
                        {
                          contact_id: opportunityRecord.contact,
                          contact_type: "SO",
                        } as basecontact,
                      ]);
                    }
                    this.showAddOpportunityDialog = false;
                    this.existingOpportunity = "";
                    this.statusMessage = "";
                    this.isLoadOppFormSubmitted = false;
                  });
              }
              this.initProspect();
            } else {
              this.isLoadingOpportunity = false;
              this.isOpportunityEmpty = true;
              this.statusMessage = "No record found";
            }
          })
          .catch(() => {
            this.isLoadingOpportunity = false;
            this.isLoadOppFormSubmitted = false;
            this.statusMessage = "Failed to load opportunity";
          });
      }
    },
    initControls() {
      const CONTROLS = [
        {
          control: "price",
          id: "PRICE",
          filename: "CONTROL",
          procedure: "price.CONTROL",
          getter: this.getPricingCodes,
        },
        {
          id: "COMPANY",
          procedure: "CO.CONTROL",
          filename: "CONTROL",
          getter: this.getCompanyCodes,
        },
      ];

      CONTROLS.forEach((element: any) => {
        if (element.getter == null || element.getter?.length === 0) {
          this.fetchControls(element);
        }
      });
    },
    // initCust() {
    //   // const cust = this.customer as Customer;
    //   const cust = this.customerInquiry.customer as Customer;
    //   this.soQuote.customer = cust.cust_id as string;
    //   this.soQuote.name = cust.name as string;
    //   // this.soQuote.contact_id = this.customer ? this.customer.contact_id_items[0].contact_id : "";
    //   this.soQuote.terms = cust.terms as string;
    //   this.soQuote.resale_no = cust.resale as string;
    //   this.soQuote.code = cust.code as string;
    //   this.newSoQuoteCust = cust.name as string;
    // },
    confirmChangeCustomer() {
      this.showChangeCustomerDialog = false;
      this.soQuote.prospect = "";
      this.soQuote.email = "";
      this.soQuote.phone = "";
      this.soQuote.fax = "";
      this.soQuote.fax = "";
    },
    checkIsActualCustomer(element: Customer, contact_id?: string) {
      this.setCust(element, contact_id);
      if (this.soQuote.customer != "") {
        this.showChangeCustomerDialog = true;
      }
    },
    setCust(element: Customer, contact_id?: string) {
      const service = new CustomerService();
      service.getCustomer(element.cust_id, this.getClient).then((response) => {
        const cust = response as Customer;
        this.soQuote.customer = cust.cust_id as string;
        if (cust.terms != null || cust.terms != undefined) {
          this.soQuote.terms = cust.terms as string;
        }
        if (cust.name != null || cust.name != undefined) {
          this.soQuote.name = cust.name;
        }

        if (cust.name != null || cust.name != undefined) {
          this.soQuote.name = cust.name;
        }

        if (cust.code != null || cust.code != undefined) {
          this.soQuote.code = cust.code;
        }

        if (cust.contact_id_items && cust.contact_id_items.length) {
          this.soQuote.contact_id = contact_id
            ? contact_id
            : (cust.contact_id_items[0].contact_id as string);
          this.soQuote.email = contact_id
            ? contact_id
            : (cust.contact_id_items[0].contact_email as string);
        }

        this.soQuote.address_items = Utils.customerShippingAddress(cust).map(
          (address: any) => {
            return { address: address };
          },
        ) as any;

        if (cust.rep_items && cust.rep_items.length) {
          (this.soQuote as any).rep_items = cust.rep_items;
        }
      });
    },
    soQuoteContact(contacts: Array<basecontact>) {
      if (contacts) {
        contacts.forEach((contact: basecontact) => {
          if (contact.contact_type == "SO") {
            const service = new ContactService(
              process.env.VUE_APP_ABSTRACTION_API,
            );
            service
              .getContacts(contact.contact_id, this.getClient)
              .then((response) => {
                let contact: any = [];
                if ((response as Array<Contact>)[0]) {
                  contact = (response as any)[0];
                } else if ((response as any)?.contact_items[0]) {
                  contact = (response as any).contact_items[0];
                }

                if (
                  contact.telephone_items != null ||
                  contact.telephone_items != undefined
                ) {
                  this.soQuote.phone = contact.telephone_items[0].telephone;
                }
                if (
                  contact.email_address_items != null ||
                  contact.email_address_items != undefined
                ) {
                  this.soQuote.email =
                    contact.email_address_items[0].email_address;
                }
                if (contact.fax != null || contact.fax != undefined) {
                  this.soQuote.fax = contact.fax;
                }
                this.soQuote.contact_id = contact.contact_id;
                this.newSoQuoteContact =
                  contact.first_name + " " + contact.last_name;
                this.soQuote.contact = `${contact.first_name} ${
                  contact.last_name ? contact.last_name : ""
                }`;
              });
          }
        });
      }
    },
    setPart(event: Part) {
      if (this.editPartNumberIndex !== -1) {
        this.soQuote.li_items[this.editPartNumberIndex].part = event.part_no;
      }
    },
    setProspect(event: Prospect) {
      this.prospectName = event.name;
      this.soQuote.prospect = event.id;
      this.soQuote.name = event.name;
    },
    initProspect() {
      if (this.soQuote.prospect) {
        this.prospectService
          .getProspect(this.soQuote.prospect)
          .then((response) => {
            this.prospectName = (response as Prospect).name as string;
          });
      }
    },
    formatDate(date: Date, field: string) {
      const formattedString = Utils.formatDate(date);
      switch (field) {
        case "PART":
          this.part.date = formattedString;
          break;
        case "STATUS_DATE":
          this.soQuote.status_date = formattedString;
          break;
        case "VALID_THRU_DATE":
          this.soQuote.valid_thru = formattedString;
          break;
        case "QUOTED_DATE":
          this.soQuote.date = formattedString;
          break;
      }
      return formattedString;
    },
    async submit() {
      this.isFormSubmitted = true;

      const isCompanyFieldPopulated = this.v$.co_code.$validate();
      const isCustFieldPopulated = this.v$.soQuote.$validate();

      if ((await isCompanyFieldPopulated) && (await isCustFieldPopulated)) {
        this.submitLoading = true;
        this.soQuote.quoted_by = this.selectedQuoter;

        if (this.isNewSalesQuote) {
          const oldData = JSON.parse(
            JSON.stringify(this.soQuote.oldRecord ?? {}),
          );
          delete oldData.saleType;
          this.soQuote.est_close = this.soQuote.est_close.toString();
          this.soQuote.exchange_rate = this.soQuote.exchange_rate.toString();
          const data = JSON.parse(JSON.stringify(this.soQuote));
          delete data.oldId;
          delete data.saleType;
          delete data.oldRecord;

          this.soQuoteService
            .putSOQuote({
              quoteId: this.soQuote.id,
              newQuote: data,
              oldQuote: oldData,
            })
            .then((response) => {
              this.submitLoading = false;
              this.removeQuotePDF(this.soQuote.id);
              this.isFormSubmitted = false;

              delete (this.soQuote as any).oldRecord;
              const oldRecord = JSON.parse(JSON.stringify(this.soQuote));
              this.soQuote.oldRecord = JSON.parse(JSON.stringify(oldRecord));

              let soQuoteId = (response as any).recordId;
              this.addNotification({
                message: `Successfully updated SO Quote Id ${soQuoteId}.`,
                type: "success",
              });
            })
            .catch((error) => {
              this.submitLoading = false;
              this.isFormSubmitted = false;
              this.addNotification({
                message: `Error: ${error.response.data.error}`,
                type: "error",
              });
            });
        } else {
          this.soQuoteService
            .postSOQuote(this.soQuote)
            .then((response) => {
              this.submitLoading = false;
              let soQuoteId = (response as any).recordId;
              this.soQuote.id = soQuoteId;
              this.isFormSubmitted = false;

              let data = JSON.parse(JSON.stringify(this.soQuote));
              data = {
                ...data,
                saleType: "quotes",
                id: soQuoteId,
                oldId: (this.$attrs.tab as any).id,
                oldRecord: JSON.parse(JSON.stringify(this.soQuote)),
              };
              this.updateOpenedSalesInquiry(data);

              delete (this.soQuote as any).oldRecord;
              const oldRecord = JSON.parse(JSON.stringify(this.soQuote));
              this.soQuote.oldRecord = JSON.parse(JSON.stringify(oldRecord));

              this.addNotification({
                message: `Successfully Created SO Quote Id ${soQuoteId}.`,
                type: "success",
              });
              this.$router.push("/sales/quotes");
              if (this.isOppLoadedInDialog) {
                const saleOppCopy = JSON.parse(JSON.stringify(this.saleOpp));
                saleOppCopy.quote_items == null
                  ? (saleOppCopy.quote_items = [{ quote: soQuoteId }])
                  : saleOppCopy.quote_items.push({ quote: soQuoteId });

                this.saleOppService
                  .updateSaleOpp(saleOppCopy, this.saleOpp)
                  .then(() => {
                    this.isOppLoadedInDialog = false;
                    this.addNotification({
                      message: `Successfully updated opportunity ${this.saleOpp.id}.`,
                      type: "success",
                    });
                    this.$router.push("/sales/quotes");
                  })
                  .catch((error) => {
                    this.addNotification({
                      message: `Error: ${error.response.data.error}`,
                      type: "error",
                    });
                  });
              }
            })
            .catch((error) => {
              this.submitLoading = false;
              this.isFormSubmitted = false;
              this.addNotification({
                message: `Error: ${error.response.data.error}`,
                type: "error",
              });
            });
        }
      } else {
        return (this.$refs.newSoQuoteCust as any).$el.focus();
      }
    },
    initSoQuote() {
      this.soQuote = {
        id: "",
        date: "",
        prospect: "",
        customer: "",
        name: "",
        phone: "",
        contact: "",
        status: "",
        quoted_by: this.selectedQuoter,
        email: "",
        fax: "",
        valid_thru: "",
        status_date: "",
        exchange_rate: "",
        cost_method: "",
        est_close: "",
        code: "",
        terms: "",
        li_items: [] as Array<LiItems>,
        address_items: [] as Array<AddressItem>,
        rev: "",
        co_code: "",
        resale_no: "",
        rep_items: [] as Array<RepItem>,
        change_date_items: [] as Array<ChangeDateItems>,
        notes: "",
        stamp_user: "",
        stamp_date: "",
        ext: "",
        reason_code: "",
        contact_id: "",
        currency_code: "",
      } as SalesInquiryMap;
      (this.newSoQuoteCust = ""),
        (this.newSoQuoteContact = ""),
        (this.prospectName = "");
    },
    async validatePartReq() {
      this.isFormSubmitted = true;
      const isCustFieldPopulated = await this.v$.newSoQuoteCust.$validate();
      if (isCustFieldPopulated) {
        this.showPartModal = true;
      }
    },
    showPartModalDialog() {
      if (!this.soQuote.customer) {
        this.addNotification({
          message: `Select a customer first`,
          type: "info",
        });
      } else {
        this.showPartSearchDialog = true;
      }
    },
    closeSearchPartDialog(event: any) {
      this.showPartSearchDialog = event;
    },
    addItem(item: any) {
      this.partLoading = true;
      this.partService
        .getPart(this.getClient, item.part_number, this.soQuote.customer, "")
        .then((response) => {
          const part = response as Part;
          const qty = item.quantity.toString();
          const line_item = {
            li: ((this.soQuote.li_items.length || 0) + 1).toString(),
            part: item.part_number,
            disc_pct: item.disc_pct,
            wrap_desc: part.wrap_desc,
            qty_items: [
              {
                qty: qty,
                price: item.price,
              },
            ],
          } as LiItems;
          this.soQuote.li_items.push(line_item);
        })
        .finally(() => {
          this.partLoading = false;
        });
    },
    focusSOQuoteSearch() {
      (this.$refs.soquoteInputText as any).$el.focus();
    },
    focusOpportunitySearch() {
      (this.$refs.opportunityInputText as any).$el.focus();
    },
    handleContactSelected(contact: any) {
      this.soQuote.contact = `${contact.first_name} ${
        contact.last_name ? contact.last_name : ""
      }`;
      this.soQuote.contact_id = contact.contact_id;
    },
    toggleSectionIcon(
      tab:
        | "customerDetails"
        | "orderDetails"
        | "pricingShippingDetails"
        | "logEntries"
        | "attachments",
    ) {
      this.sectionsStatus[tab] = !this.sectionsStatus[tab];
    },
    downloadQuote() {
      this.getQuotePDF({
        recordId: this.soQuote.id,
      });
    },
    handleEmailQuote() {
      this.showEmailDialog = true;
    },
    updateReps(reps: any) {
      this.soQuote.rep_items = reps;
    },
    updateCustomer(event: any) {
      customerService
        .getCustomer(
          event.cust_id,
          this.getClient,
          "contact_name contact_email",
        )
        .then((response) => {
          this.setPosCustomer({ cust_items: [response] });
          this.setCust(response as Customer);
        });
    },
    updateAddress(address: any) {
      this.soQuote.address_items = address;
    },
    sendEmail(data: any) {
      this.soQuoteService
        .getQuote(this.soQuote.id, data)
        .then((response) => {
          if (response === "success") {
            this.addNotification({
              message: `Quote #${this.soQuote.id} has been emailed successfully`,
              type: "success",
            });
          } else {
            this.addNotification({
              message: `Quote was not sent`,
              type: "error",
            });
          }
        })
        .catch((error) => {
          this.addNotification({
            message: `Quote could not be sent: ${error}`,
            type: "error",
          });
        });
    },
  },
});
