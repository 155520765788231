
import { defineComponent } from "vue";
import { mapGetters, mapActions } from "vuex";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import Log from "@/types/logs";

import DataTable from "primevue/datatable";
import Column from "primevue/column";
import ProgressSpinner from "primevue/progressspinner";
import InputText from "primevue/inputtext";
import AddLogEntryDialog from "@/components/UI/AddLogEntryDialog.vue";

import LogService from "@/services/LogService";
import Utils from "@/utility/utils";

export default defineComponent({
  name: "LogsDataTable",
  props: {
    elementId: {
      type: String,
      required: true,
    },
    file: {
      type: String,
      required: true,
    },
    showActions: {
      type: Boolean,
      default: false,
    },
    associatedTypes: {
      type: Array,
      required: true,
    },
  },
  components: {
    DataTable,
    Column,
    InputText,
    ProgressSpinner,
    AddLogEntryDialog,
  },
  data() {
    return {
      newLog: {} as Log,
      internalLogs: [] as Log[],
      logService: new LogService(process.env.VUE_APP_ABSTRACTION_API),
      isLoadingLogEntry: false,
      isSaving: false,
      filters: {
        created_by: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        type: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        subject: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        date_created: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      getUserId: "session/getUserId",
      getClient: "session/getClient",
    }),
  },
  async created() {
    await this.getLogs();
  },
  methods: {
    ...mapActions({
      addNotification: "notification/add",
      fetchContactLog: "customerInquiry/getContactLog",
      postLog: "customerInquiry/postLog",
      getLogControl: "logControl/getLogControl",
    }),
    async getLogs() {
      if (this.elementId) {
        this.isLoadingLogEntry = true;
        this.logService
          .getOpportunityLogs(this.elementId, this.file)
          .then((response: any) => {
            if (response) {
              this.internalLogs = response.log_items;
            } else {
              this.internalLogs = [];
            }
          })
          .catch((error: any) => {
            console.log(error);
          })
          .finally(() => {
            this.isLoadingLogEntry = false;
          });
      }
    },
    async addLog(data: any) {
      this.isSaving = true;
      this.newLog = { ...data };
      this.formatDateObj();
      this.formatTimeObj();
      this.newLog.created_by = this.getUserId;
      this.newLog.assoc_file_items = this.associatedTypes as any;
      this.saveNewLog();
    },
    formatDateObj() {
      this.newLog.date_created = Utils.formatDate(new Date()).replaceAll(
        "-",
        "/",
      );
    },
    formatTimeObj() {
      this.newLog.time_created = Utils.formatTime(new Date()).substring(0, 5);
    },
    saveNewLog() {
      this.logService
        .postLog(this.getClient, this.newLog)
        .then((response: any) => {
          const id = response.response.recordId;
          this.internalLogs.unshift({ ...this.newLog, id });

          const notification = {
            type: "success",
            message: `Log ${id} saved successfully`,
          };
          this.addNotification(notification);
        })
        .catch((error: any) => {
          const notification = {
            type: "error",
            message: `Error saving the log: ${error}`,
          };
          this.addNotification(notification);
          console.log(error);
        })
        .finally(() => {
          this.newLog = {} as Log;
          this.isSaving = false;
        });
    },
  },
});
